<!--
 * @Author: your name
 * @Date: 2021-11-08 10:49:52
 * @LastEditTime: 2022-01-08 19:49:36
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \three_year\src\components\Modal.vue
-->
<template>
  <div class="mask" v-if="show" @click.self="maskClick">
    <div class="content">
      <slot></slot>
      <div class="close_btn" @click="close"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Modal",
  props: {
    // 控制弹框显示
    show: {
      type: Boolean,
      required: true,
    },
    // 点击mask是否需要关闭
    maskClose: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {
    show(val) {
      if (val) {
        document.body.style = "height:100%;overflow:hidden;";
      } else {
        document.body.style = "";
      }
    },
  },
  methods: {
    maskClick() {
      this.maskClose && this.close();
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "./Modal.less";
</style>
