<!--
 * @Author: your name
 * @Date: 2021-12-08 15:54:42
 * @LastEditTime: 2022-01-20 18:10:58
 * @LastEditors: Please set LastEditors
 * @Description: 绑定角色
 * @FilePath: \saier-christmas\src\components\aboutLogin\bind.vue
-->
<template >
	<SelfModal :show="show" @close="close">
		<div class="tit">请绑定您的区服角色</div> 
		<div class="form_box">

			<div class="form_item">
				<span>请选择平台：</span>
				<div class="my_select ellipsis" @click="showpicker('channel')" >
					{{ channel.name }}
					<div class="triangle"></div>
				</div>
			</div>
			<van-loading v-if="loading" size="24px" class="mt100" vertical >查询角色中...		 </van-loading>

			<div class="form_item" v-if="!loading">
				<span>请选择区服：</span>
				<div class="my_select ellipsis" @click="showpicker('server')" >
					{{ chooseOne ? chooseOne.server_name :''}}
					<div class="triangle"></div>
				</div>
			</div>

			<div class="form_item" v-if="!loading">
				<span>请选择角色：</span>
				<div class="my_select ellipsis" @click="showpicker('server')" >
					{{ chooseOne ? chooseOne.role_name:'' }}
					<div class="triangle"></div>
				</div>
			</div>
			<van-popup v-model="showpop"  position="bottom"  get-container="body">
				<van-picker show-toolbar :columns="popArr" :value-key="key" @cancel="closepop" @confirm="onConfirm"> </van-picker>
			</van-popup>

			<div class="login_btn" @click="sure">确定</div>
		</div>
	</SelfModal>
</template>
<script>
import SelfModal from "./Modal.vue";
import { getRoleInfo,bindRole} from "@/utils/api.js";
export default {
  props: {
    show: {
      type: Boolean,
    },
  },
  components: { SelfModal: SelfModal },
  name: "",
  data() {
    return {
			loading:false,
			channel:{ type: '', name: '' },
      roleId: "",
			// 选择器弹框内容
      poptype:'',
			// 控制选择器显示
			showpop:false,
			// 角色列表
      list: [],
			// ios角色列表
			iosList:[],
			// 安卓角色列表
			andrList:[],
      chooseOne: {
        server_name: "",
        role_name: "",
      },
      platarr: [
        { type: 1, name: "ios" },
        { type: 2, name: "Android" },
      ],
			// 选择器参数
			popArr:[],
			key:'',
    };
  },
  watch: {
		show(val){
			if(val){
				// this.getRole(1)
				// this.getRole(2)
			}else {
				this.channel={ type: '', name: '' }
				this.chooseOne= {
					server_name: "",
					role_name: "",
				}
			}
		}
	},
  computed: {},
  methods: {
    close() {
      this.$store.commit("SETVAL", { bindrole: false });
    },
    closepop() {
      this.showpop= false
    },
    showpicker(type) {
      // if (this.list.length < 1) {
      //   return;
      // }
			let obj = {
				'channel':'name',
				'role':'role_name',
				'server':'server_name',
			}
			
			if(type!=='channel'  ) {
				if(!this.channel) {
					this.$toast('请先选择平台')
					return
				}else {
					this.list = 	this.channel.type == 1 ? this.iosList :this.andrList
					// if(this.list.length <1){
					// 	this.$toast("当前用户还未创建角色，请前往游戏创建！");
					// }	
				}
			}
      this.poptype = type;
			this.showpop = true
			this.popArr = type=='channel' ? this.platarr :this.list;
			this.key = obj[type]
    },
    // 根据角色ID，获取角色名和区服
    getServerRole() {
      this.chooseOne = {
        server_name: "",
        role_name: "",
      };
      if (/^[0-9]{6,12}$/.test(this.roleId)) {
        let time = parseInt(new Date().getTime());
        let access = this.$encrypte([time]);
        let uid = localStorage.getItem('uid')
        getQDRoleInfo({
          time: time,
          role_id: this.roleId,
          access: access,
          uid,uid
        })
          .then((data) => {
            if (data.server_id) {
              this.chooseOne = data;
            } else {
              this.$toast("未查询到该角色信息");
            }
          })
          .catch((err) => {
            this.$toast(err.msg || "获取角色信息失败--");
          });
      } else {
        this.roleId !== "" && this.$toast("请输入6-12位数字ID");
      }
    },
    // 获取角色信息
    getRole(type) {
      let time = parseInt(new Date().getTime());
      let token = localStorage.getItem('token')|| ''
      let uid = localStorage.getItem('uid')|| ''
			if(!token || !uid){
				this.$store.commit('SETVAl',{loginStep:'logintype',bindrole:false})
				return 
			}
			this.loading =  true
      getRoleInfo({
        time: time,
        channel: type,
        token: token,
        uid:uid,
        access: [time, token],
      })
        .then((data) => {
					this.loading =  false
          // this.list = data;
					if(type == 1){
						this.iosList = data || []
					}else {
						this.andrList = data || []
					}
        })
        .catch((err) => {
					this.loading =  false
          this.list = [];
          if (err.status == 0) {
            this.$toast("当前用户还未创建角色，请前往游戏创建！");
          } else {
            this.$toast(err.msg || "获取角色信息失败");
          }
        });
    },
    onConfirm(el) {
			if(this.poptype == 'channel') {
				this.channel = el
				this.getRole(el.type)
			}else {
				this.chooseOne = el
			}
			this.poptype = '';
			this.showpop = false
      // this[type] = false;
      // el && (this.chooseOne = el);
			
    },
    // 绑定区服角色信息
    sure() {
			if (!this.channel.type) {
				this.$toast('请选择平台')
				return
			};
      if (!this.chooseOne.role_name) {
				this.$toast('请选择区服角色')
				return
			};
      // let uid = localStorage.getItem("uid");
      let time = parseInt(new Date().getTime());
      let token = localStorage.getItem('token')|| ''
      let uid = localStorage.getItem('uid')|| ''
			if(!token){
				this.$store.commit('SETVAl',{loginStep:'logintype',bindrole:false})
				return 
			}
      let params = {
        time: time,
        channel: this.channel.type,
				token:token,
        sid: this.chooseOne.server_id, //区服id
        sname: this.chooseOne.server_name, //区服名称
        rolename: this.chooseOne.role_name, //角色id
        rid: this.chooseOne.role_id, //角色名称
        access: [time+token],
        uid:uid
      };
			bindRole(params).then(data=>{
				this.$toast('角色绑定成功')
				this.$store.commit("SETVAL", { bindrole: false,  });
				localStorage.setItem('server',JSON.stringify(this.chooseOne))
				localStorage.setItem('channel',JSON.stringify(this.channel))
				this.$store.dispatch('getUserInfo',token)
			}).catch(err=>{
				this.$toast(err.msg || '绑定失败')
			})
    },
  },
  mounted() {},
};
</script>